import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import "./imageModal.scss"

import { makeBlob, mimicDownload } from "@samvera/image-downloader";

function ImangeView({handleClose,show,matchData}) {
  const url = matchData?.image;
  const filename = matchData?.image.split("profile/")[1]
  return (
    <div>
         <Modal show={show} onHide={handleClose} animation={false}   dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"    centered>
        <Modal.Header closeButton>
          <Modal.Title>Foto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className='row-class'>
                <Col sm={12} md={6} lg={6}>
                    <div className="image-wrapper">
                        <img src={matchData?.image} alt="demo" />
                       
                    </div>
                </Col>
                <Col sm={12} md={6} lg={6}>
                    <div className='content-wrapper'>
                        <h1>{matchData?.code}</h1>
                       <div className='table-content'>
                        <p className='firstCol'>Nombre</p>
                        <p className='secondCol'>{matchData?.name}</p>
                       </div>
                       <div className='table-content'>
                        <p className='firstCol'>Apellido</p>
                        <p className='secondCol'>{matchData?.surname}</p>
                       </div>
                       <div className='table-content'>
                        <p className='firstCol'>Edad</p>
                        <p className='secondCol'>{matchData?.age}</p>
                       </div>
                       <div className='table-content'>
                        <p className='firstCol'>Año de nac.</p>
                        <p className='secondCol'>{matchData?.year_of_birth}</p>
                       </div>
                    </div>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
        <Button  onClick={async () => {
    let response = await makeBlob(url);

   
    if (!response || response.error) {
    
      alert("Error fetching the image");

      return;
    }

    mimicDownload(response, filename);
  }}  className='download-btn'   >
      Descargar <img src="/images/download.png" alt="photo11" />
    </Button>
         
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ImangeView