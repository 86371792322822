import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { postData } from "../../api/api";
import { ToastContainer, toast } from 'react-toastify';
import "./login.scss";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";

function Login() {
  const [cookies, setCookie] = useCookies(['id', 'email', 'token','name']);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    
  const navigate = useNavigate();
    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'email') {
        setEmail(value);
      } else if (name === 'password') {
        setPassword(value);
      }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
          const response = await postData(email, password);
          console.log("response",response)
          setCookie('id', response?.user?.id, { path: '/' }); 
          setCookie('email', response?.user?.email, { path: '/' }); 
          setCookie('lastActivity', response?.user?.lastActivity, { path: '/' });
          setCookie('token', response?.token, { path: '/' });
          console.log('Login successful:', response);
          toast.success('Login successful');
          navigate("/dashboard")
          setLoading(false);
        } catch (error) {
          console.error('Login failed:', error);
          toast.error('Login failed');
          setLoading(false);
        }
      };
    
  return (
    <div className="login-section">
      <img className="logo" src="/images/logo.png" alt="" />
      <div className="login-form">
        <h1>Inicia sesión para acceder al dashboard</h1>
 
        <Form className="form" onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Correo</Form.Label>
            <Form.Control  value={email} name="email"
          onChange={handleChange} type="email" placeholder="Correo" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control name="password"  value={password}
          onChange={handleChange} type="password" placeholder="Contraseña" />
          </Form.Group>

          <Button className="login-btn" variant="primary" type="submit" disabled={loading}>
             {loading ? 'Logging in...' : 'Ingresar'}
          </Button>
        </Form>
      </div>
      <ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"

/>
    </div>
  );
}

export default Login;
