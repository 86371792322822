import axios from "axios";

const API_URL = "https://api.skupanama.com";


export const fetchData = async (currentPage,token) => {
  console.log("token",token)
  try {
    const response = await axios.get(`${API_URL}/dashboard/data`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const postData = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/admin/login`, {
      email,
      password,
    });

    if (response.data) {
    }

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};


export const logout = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/admin/logout`, { email });
    return response.data;
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  }
};