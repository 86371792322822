import {Route, Routes } from "react-router-dom";
import './App.css';
import Login from "./components/login/Login";
import Dashboard from "./components/dashboard/Dashboard";
import { useCookies } from "react-cookie";

function App() {
  const [cookies, setCookie] = useCookies(['id', 'email', 'token']);
  const auth = cookies?.token
  return (
    <div className="App">
    <Routes>
      <Route exec path="/" element={<Login/>} />
      {
  auth ? (
    <Route exec path="/dashboard" element={<Dashboard />} />
  ) : ""
}
    </Routes>
    </div>
  );
}

export default App;
