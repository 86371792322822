import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import { Button, Dropdown, Form, Spinner } from "react-bootstrap";
import { fetchData, logout } from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import ImangeView from "../imageModal/ImangeView";
function Dashboard() {
  const [data, setData] = useState([]);
  const [cookies, setCookie] = useCookies(["id", "email", "token"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(15);
  const [totalCount, setTotalCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [matchData, setMatchData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const token = cookies?.token;
  const name = cookies?.email?.split("@")[0];
  const email = cookies?.email;
  const lastActivity = cookies?.lastActivity;

  

  const [toastShown, setToastShown] = useState(false);

useEffect(() => {
  let isMounted = true; 
  fetchData(currentPage, token)
    .then((response) => {
      if (isMounted) {
        setData(response?.result);
        setTotalCount(response?.result?.length || 0);
        if (!toastShown) {
          toast.success("Welcome To Dashboard");
          setToastShown(true); 
        }
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      toast.error("Access Denied");
    })
    .finally(() => {
      if (isMounted) {
        setLoading(false);
      }
    });


  return () => {
    isMounted = false;
  };
}, [currentPage, toastShown]); 


  const handleNextPage = () => {
    const nextStartRecord = currentPage * pageSize + 1;

    if (nextStartRecord <= totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startRecord = (currentPage - 1) * pageSize + 1;
  const endRecord = Math.min(currentPage * pageSize, totalCount);

  const filteredData = data.filter((item) => {
    const fullName = `${item.name} ${item.surname}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });
  console.log("filteredData", filteredData);
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      const email = cookies.email;
      const response = await logout(email);

      console.log("Login successful:", response);
      toast.success("logout successful");
      navigate("/");
    } catch (error) {
      console.error("Login failed:", error);
      toast.error("Logout failed");
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    if (id !== "" || id !== undefined) {
      const selectedItem = data.find((item) => item.id === id);
      setMatchData(selectedItem);
    }
  };

  return (
    <div>
      <div className="top-bar">
        <img src="/images/logo.png" alt="logo" />
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <img className="user" src="./images/user-circle.png" alt="logo" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.ItemText>
              {" "}
              <span>{name}</span>
              <br /> {email}
            </Dropdown.ItemText>

            <Dropdown.Item onClick={handleLogout} href="#/action-3">
              Cerrar sesión
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="dashboard-wrapper">
        <div className="sidebar">
          <div className="list-tab-wrapper">
            <div className="list-tab">
              <img
                className="list"
                src="./images/view-list.png"
                alt="view-list"
              />
              Listado fotos
            </div>
          </div>
        </div>
        <div className="dashboard">
          <div className="search-box">
            <Form.Control
              type="text"
              placeholder="Buscar usuario"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="content-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th>FOTO</th>
                  <th>NOMBRE</th>
                  <th>APELLIDO</th>
                  <th>EDAD</th>
                  <th>AÑO DE NAC.</th>
                  <th>CODIGO</th>
                  <th>ACCIÓN</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div className="spinner-wrapper">
      <Spinner animation="border" variant="primary" />
    </div>
                ) : filteredData.length === 0 ?  (
                  <div className="spinner-wrapper">
              <h2 className="norecord">no hay registros</h2>
            </div>
                ) : (
                filteredData
                  .slice(startRecord - 1, endRecord)
                  .map((ele, index) => (
                    <tr key={index}>
                      <td>
                        <img src={ele.image} alt={index} />
                      </td>
                      <td>{ele.name}</td>
                      <td>{ele.surname}</td>
                      <td>{ele.age}</td>
                      <td>{ele.year_of_birth}</td>
                      <td>{ele.code}</td>
                      <td>
                        <Button
                          variant="primary"
                          className="view-btn"
                          onClick={() => handleShow(ele?.id)}
                        >
                          Ver foto
                        </Button>
                      </td>
                    </tr>
                  )))}
              </tbody>
            </table>
          


          </div>
          <div className="pagination-bar">
            <div className="pagination">
              <div className="chevrons">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <img src="/images/chevron-left.png" alt="paginarion" />
                </button>
                <button
                  onClick={handleNextPage}
                  disabled={endRecord >= totalCount}
                >
                  <img src="/images/chevron-right.png" alt="image-pages" />
                </button>
              </div>
              <p>
                show{" "}
                <span>
                  {startRecord} - {endRecord}
                </span>{" "}
                of <span>{totalCount}</span>
              </p>
            </div>
            <div className="activity">
              <p>
                Last account activity: <span>{lastActivity}</span>
              </p>
              <img src="/images/eye.png" alt="eye" />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ImangeView handleClose={handleClose} show={show} matchData={matchData} />
    </div>
  );
}

export default Dashboard;
